var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"documents-container"},[_c('PaginationTable',{attrs:{"itemsInPage":_vm.itemsPerPage,"getTotalCount":_vm.itemsCount,"getPageContent":_vm.fetchPage,"addItemsFull":false},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var refresh = ref.refresh;
return [_c('div',{staticClass:"header-filter"},[_c('h2',[_vm._v(_vm._s(_vm.translations("MyDocuments")))]),_c('div',{staticClass:"doc-filter"},[_c('div',{staticClass:"filter-item"},[_c('label',{class:{ active: _vm.documentType === 'dogovor' }},[_c('input',{attrs:{"type":"radio","name":"doc_type","value":"dogovor","checked":"checked"},on:{"click":function($event){return _vm.reloadDocs('dogovor', refresh)}}}),_c('span',[_vm._v(_vm._s(_vm.translations("Agreement")))])])]),_c('div',{staticClass:"filter-item"},[_c('label',{class:{ active: _vm.documentType === 'invoices' }},[_c('input',{attrs:{"type":"radio","name":"doc_type","value":"invoices"},on:{"click":function($event){return _vm.reloadDocs('invoices', refresh)}}}),_c('span',[_vm._v(_vm._s(_vm.translations("Invoices")))])])]),_c('div',{staticClass:"filter-item"},[_c('label',{class:{ active: _vm.documentType === 'acts' }},[_c('input',{attrs:{"type":"radio","name":"doc_type","value":"acts"},on:{"click":function($event){return _vm.reloadDocs('acts', refresh)}}}),_c('span',[_vm._v(_vm._s(_vm.translations("Acts")))])])])])])]}},{key:"document",fn:function(ref){
var curItem = ref.curItem;
return [_c('div',{staticClass:"doc-content"},[(_vm.documentType === 'dogovor')?_c('a',{staticClass:"image",attrs:{"href":'//' + _vm.imagesPath + curItem.file,"target":"_blank"}},[_c('img',{attrs:{"src":'//' + _vm.imagesPath + '/upload/image/egrul/document1.png',"alt":""}})]):_vm._e(),(_vm.documentType !== 'dogovor')?_c('div',{staticClass:"image",on:{"click":function($event){return _vm.getDocument(curItem.id)}}},[_c('img',{attrs:{"src":'//' +
              _vm.imagesPath +
              (_vm.documentType === 'invoices'
                ? '/upload/image/egrul/invoice_example.jpg'
                : '/upload/image/egrul/act_example.jpg'),"alt":""}})]):_vm._e(),_c('div',{staticClass:"text"},[_vm._v(_vm._s(curItem.text))])]),_c('div',{staticClass:"doc-date"},[_vm._v(_vm._s(curItem.date))])]}},{key:"empty",fn:function(){return undefined},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }