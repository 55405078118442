<template>
  <div class="table-container">
    <div class="header-and-table">
      <slot name="header" :refresh="refreshList" />
      <div class="table" :class="listClass" v-if="itemsFetched && items.length">
        <div
          class="table-row"
          :class="itemClass"
          v-for="item in items"
          :key="item.id"
        >
          <slot name="document" :curItem="item" :refresh="refreshList">
            {{ item.text }}
          </slot>
        </div>
      </div>
      <div class="loading-tabledata" v-if="!itemsFetched">
        <Loader />
      </div>
      <slot name="empty" v-if="itemsFetched && !items.length" />
    </div>
    <div class="footer-and-pagination">
      <slot name="footer" />
      <paginate
        v-if="items && pageCount > 1"
        v-model="page"
        :page-count="pageCount"
        :page-range="3"
        :click-handler="changePage"
        :prev-text="prevPageHtml"
        :next-text="nextPageHtml"
        :container-class="'pagination'"
        :next-class="'standalone go-to-next'"
        :prev-class="'standalone go-to-prev'"
        :hide-prev-next="true"
        :page-class="'page-item'"
      >
      </paginate>
    </div>
    <slot name="update" />
  </div>
</template>

<script>
import Loader from "@/components/app/Loader";
import paginationMixin from "@/mixins/pagination.mixin";

export default {
  name: "PaginationTable",
  components: { Loader },
  mixins: [paginationMixin],
  props: {
    getTotalCount: {
      type: Function,
      required: true,
    },
    getPageContent: {
      type: Function,
      required: true,
    },
    listClass: String,
    itemClass: String,
    itemsInPage: {
      type: Number,
      default: 10,
    },
    addItemsFull: {
      type: Boolean,
      default: true,
    },
    pushState: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      itemsFetched: false,
      items: [],
      itemsAdded: 0,
      addingInterval: null,
      itemDelay: 100,
    };
  },
  methods: {
    /*
    addItem() {
      const item = this.storedItems[this.itemsAdded];
      if (item) {
        this.items.push(item);
        this.itemsAdded++;
      } else {
        clearInterval(this.addingInterval);
      }
    },
    */
    async refreshList() {
      console.log("refreshing list, page: ", this.page);
      this.itemsFetched = false;
      let totalCount = await this.getTotalCount();
      this.pageCount = Math.ceil(totalCount / this.pageSize);
      this.items = await this.getPageContent(this.page);
      this.itemsFetched = true;
    },
    async changePage(page) {
      console.log("changePage page: ", page);

      // переходить на страницу пагинации, если нужно
      if (this.pushState) {
        let fullpath = `${this.$route.path}`;
        let queryEmpty = true;
        console.log({ ...this.$route.query });
        if (this.$route.query.query) {
          fullpath += `?query=${this.$route.query.query}`;
          queryEmpty = false;
        }
        if (this.$route.query.country) {
          fullpath += `?country=${this.$route.query.country}`;
          queryEmpty = false;
        }
        if (page > 1) {
          fullpath += (queryEmpty ? "?" : "&") + `page=${page}`;
        }
        this.$router.push(fullpath);
      }
      this.items = await this.getPageContent(page);
      /*
      console.log("storedItems: ", this.storedItems);
      this.storedItems = await this.getPageContent(page);
      if (this.addItemsFull) {
        this.items = this.storedItems;
      } else {
        this.itemsAdded = 0;
        this.items = [];
        this.addingInterval = setInterval(this.addItem, this.itemDelay);
      }
      */
    },
  },
  async mounted() {
    this.pageSize = this.itemsInPage;
    let totalCount = await this.getTotalCount();
    console.log("total count: ", totalCount);
    this.pageCount = Math.ceil(totalCount / this.pageSize);
    this.items = await this.getPageContent(this.page);
    this.itemsFetched = true;
    /*
    this.storedItems = await this.getPageContent(this.page);
    if (this.addItemsFull) {
      this.items = this.storedItems;
    } else {
      this.itemsAdded = 0;
      this.items = [];
      this.addingInterval = setInterval(this.addItem, this.itemDelay);
    }
    */
  },
  beforeDestroy() {
    //clearInterval(this.addingInterval);
  },
};
</script>