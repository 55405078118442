export default {
  data: function() {
    return {
      page: +this.$route.query.page || 1,
      pageSize: 10,
      pageCount: 0,
      prevPageHtml: '<a class="btn prev-page"><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.11257 3.54756L7.61896 3.54756L7.61896 4.45232L2.11257 4.45232L4.53915 6.87889L3.89948 7.51855L0.380859 3.99994L3.89948 0.481318L4.53915 1.12098L2.11257 3.54756Z" fill="#2D2E3C"/></svg></a>',
      nextPageHtml: '<a class="btn prev-page"><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.88743 4.45245L0.381045 4.45245L0.381045 3.54768L5.88743 3.54768L3.46085 1.12111L4.10052 0.481446L7.61914 4.00006L4.10052 7.51868L3.46086 6.87902L5.88743 4.45245Z" fill="#2D2E3C"/></svg></a>',
      items: [],
    }
  },
}