<template>
  <div class="documents-container">
    <PaginationTable
      :itemsInPage="itemsPerPage"
      :getTotalCount="itemsCount"
      :getPageContent="fetchPage"
      :addItemsFull="false"
    >
      <template v-slot:header="{ refresh }">
        <div class="header-filter">
          <h2>{{ translations("MyDocuments") }}</h2>
          <div class="doc-filter">
            <div class="filter-item">
              <label :class="{ active: documentType === 'dogovor' }">
                <input
                  type="radio"
                  name="doc_type"
                  value="dogovor"
                  checked="checked"
                  @click="reloadDocs('dogovor', refresh)"
                />
                <span>{{ translations("Agreement") }}</span>
              </label>
            </div>
            <div class="filter-item">
              <label :class="{ active: documentType === 'invoices' }">
                <input
                  type="radio"
                  name="doc_type"
                  value="invoices"
                  @click="reloadDocs('invoices', refresh)"
                />
                <span>{{ translations("Invoices") }}</span>
              </label>
            </div>
            <div class="filter-item">
              <label :class="{ active: documentType === 'acts' }">
                <input
                  type="radio"
                  name="doc_type"
                  value="acts"
                  @click="reloadDocs('acts', refresh)"
                />
                <span>{{ translations("Acts") }}</span>
              </label>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:document="{ curItem }">
        <div class="doc-content">
          <a
            v-if="documentType === 'dogovor'"
            :href="'//' + imagesPath + curItem.file"
            target="_blank"
            class="image"
          >
            <img
              :src="'//' + imagesPath + '/upload/image/egrul/document1.png'"
              alt=""
            />
          </a>
          <div
            v-if="documentType !== 'dogovor'"
            class="image"
            @click="getDocument(curItem.id)"
          >
            <img
              :src="
                '//' +
                imagesPath +
                (documentType === 'invoices'
                  ? '/upload/image/egrul/invoice_example.jpg'
                  : '/upload/image/egrul/act_example.jpg')
              "
              alt=""
            />
          </div>
          <div class="text">{{ curItem.text }}</div>
        </div>
        <div class="doc-date">{{ curItem.date }}</div>
      </template>
      <template v-slot:empty> </template>
    </PaginationTable>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import PaginationTable from "@/components/PaginationTable";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserDocuments",
  components: {
    PaginationTable,
  },
  data: () => {
    return {
      imagesPath: VUE_APP_BACK_URL,
      itemsPerPage: 5,
      documentType: "dogovor",
    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "listDocumentsLoaded",
      "listDocumentsCount",
      "listDocuments",
      "listDocumentsPage",
    ]),
  },
  methods: {
    ...mapActions([
      "userDocuments",
      "clearUser",
      "userGetAct",
      "userGetInvoice",
    ]),
    async getDocument(id) {
      if (this.documentType === "acts") {
        await this.userGetAct({
          lang: this.$cookies.get("user_language"),
          id,
        });
      } else if (this.documentType === "invoices") {
        await this.userGetInvoice({
          lang: this.$cookies.get("user_language"),
          id,
        });
      }
    },
    async reloadDocs(type, refresh) {
      console.log(type);
      this.documentType = type;
      await this.clearUser();
      await refresh();
    },
    async itemsCount() {
      console.log("получаем кол-во документов + сами документы");
      if (!this.listDocumentsLoaded) {
        await this.userDocuments({
          lang: this.$cookies.get("user_language"),
          type: this.documentType,
          page: +this.$route.query.page || 1,
          pagesize: this.itemsPerPage,
        });
      }
      return this.listDocumentsCount;
    },
    async fetchPage(page) {
      if (+this.listDocumentsPage !== +page) {
        console.log("получаем сами документы");
        await this.userDocuments({
          lang: this.$cookies.get("user_language"),
          type: this.documentType,
          page,
          pagesize: this.itemsPerPage,
        });
      }
      return this.listDocuments;
    },
  },
  async mounted() {},
};
</script>